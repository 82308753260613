import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private loadingBS: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loading$: Observable<boolean> = this.loadingBS.asObservable();

  private loadingTopicsBS: BehaviorSubject<Map<string, number>> = new BehaviorSubject<Map<string, number>>(
    new Map<string, number>()
  );
  public loadingTopics$: Observable<Map<string, number>> = this.loadingTopicsBS.asObservable();

  public startLoading(): void {
    this.loadingBS.next(true);
  }

  public stopLoading(): void {
    this.loadingBS.next(false);
  }

  public increaseTopic(topic: string): void {
    const loadingTopics: Map<string, number> = this.loadingTopicsBS.getValue();
    if (loadingTopics.has(topic)) {
      loadingTopics.set(topic, loadingTopics.get(topic) + 1);
    } else {
      loadingTopics.set(topic, 1);
    }
    this.loadingTopicsBS.next(loadingTopics);
  }

  public decreaseTopic(topic: string): void {
    const loadingTopics: Map<string, number> = this.loadingTopicsBS.getValue();
    if (loadingTopics.has(topic)) {
      const currentNumber = loadingTopics.get(topic);
      if (currentNumber > 1) {
        loadingTopics.set(topic, currentNumber - 1);
      } else {
        loadingTopics.delete(topic);
      }
      this.loadingTopicsBS.next(loadingTopics);
    }
  }
}
